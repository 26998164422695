import Vue from 'vue'
import * as types from './types'
import {uniq} from "lodash-es";

export default {
  [types.LOAD_STATE](state, data) {
    if (data === undefined) return;

    for (const k in data) {
      if (k !== 'vueMeta') {
        if (data.page) {
          Vue.set(state, 'page', data.page);
          Vue.set(state, 'last_page', data.last_page);
          Vue.set(state, 'products', uniq(state.products.concat(data.products)));
        } else if (data.clients) {
          Vue.set(state, 'clients', data.clients);
        }
      }
    }
  },
  [types.SET_LOADING](state, pending) {
    if (pending === undefined) return;
    state.loading = pending;
  },
  [types.SET_CURRENT_PRODUCT](state, product) {
    state.current_product = product;
  },
  [types.ADD_PRODUCT](state, product) {
    state.products.push(product);
  },
  [types.UPDATE_PRODUCT](state, product) {
    let index = state.products.findIndex((p) => {
      return p.id === parseInt(product.id);
    });
    if (index !== -1) {
      Vue.set(state.products, index, product);
    }
  },
  [types.REMOVE_PRODUCT](state, productId) {
    let index = state.products.findIndex((product) => {
      return product.id === parseInt(productId);
    });
    if (index !== -1) state.products.splice(index, 1);
  },
  [types.SET_PRODUCTS](state, products) {
    Vue.set(state, 'products', [...products]);
    Vue.set(state, 'page', 0);
  },
  [types.SET_CURRENT_BARCODE](state, barcode) {
    Vue.set(state, 'previous_barcode', state.current_barcode);
    Vue.set(state, 'current_barcode', barcode);
  },
  [types.SET_CURRENT_ORDER](state, order) {
    Vue.set(state, 'current_order', order);
  },
  [types.ADD_PRODUCT_TO_ORDER](state, data) {
    let index;
    if (data.product) {
      index = state.current_order.findIndex((i) => {
        return i.product.sku === data.product.sku
      });
    }
    if (index !== -1) {
      Vue.set(state.current_order, index, data);
    } else {
      state.current_order.push(data);
    }
  },
  [types.SET_CURRENT_INVENTORY](state, inventory) {
    Vue.set(state, 'current_inventory', inventory);
  },
  [types.ADD_PRODUCT_TO_INVENTORY](state, data) {
    let index;
    if (data.product) {
      index = state.current_inventory.findIndex((i) => {
        return i.product.sku === data.product.sku
      });
    }
    if (index !== -1) {
      Vue.set(state.current_inventory, index, data);
    } else {
      state.current_inventory.push(data);
    }
  }
}