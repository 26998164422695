import AppNav from '../components/AppNav';
import AppTable from '../components/AppTable';
import AppDialog from '../components/AppDialog';
import AppImportDialog from '../components/AppImportDialog';
import AppInventoryDialog from '../components/AppInventoryDialog';
import AppSaleDialog from '../components/AppSaleDialog';
import AppScanDialog from '../components/AppScanDialog';
import AppProductFormDialog from '../components/AppProductFormDialog';

export default {
  install(Vue, _) {
    Vue.component('AppNav', AppNav);
    Vue.component('AppTable', AppTable);
    Vue.component('AppDialog', AppDialog);
    Vue.component('AppImportDialog', AppImportDialog);
    Vue.component('AppInventoryDialog', AppInventoryDialog);
    Vue.component('AppSaleDialog', AppSaleDialog);
    Vue.component('AppScanDialog', AppScanDialog);
    Vue.component('AppProductFormDialog', AppProductFormDialog);
  },
  createdHook(initialState) {
    return function() {
      this.$store.dispatch('loadInitialState', initialState);
    }
  },
  mountedHook() {
    return function() {
      this.$store.dispatch('loadData').catch(() => {
        console.log('Error loading data');
      });
    }
  }
}