<template>
  <div>
    <div class="modal fade" :class="{ 'in show': show }">
      <div :class="'modal-dialog modal-' + size">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" v-show="title">{{title}}</h4>
            <button v-show="isDismissable"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="modal-backdrop loader fade"
         :class="{ 'in show': show }"></div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String
      },
      show: {
        type: Boolean,
        default: false
      },
      isDismissable: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: 'lg'
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      handleEscapeKey(e) {
        if (this.show === true && this.isDismissable === true && e.keyCode == 27) {
          this.close();
        }
      }
    },
    watch: {
      show(val) {
        const el = document.body;
        if (val) {
          el.classList.add('modal-open');
        } else {
          el.classList.remove('modal-open');
        }
      }
    },
    mounted() {
      document.addEventListener("keydown", this.handleEscapeKey);
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.handleEscapeKey);
    }
  }
</script>
