const messages = {
  ro: {
    add_manual_item: "Adăugați carte manual",
    create: "Salvează",
    edit: "Modifică",
    update: "Salvează",
    delete: "Șterge",
    save: "Salvează",
    cancel: "Renunță",
    sku: "Cod ISBN",
    title: "Titlu",
    author: "Autor",
    price: "Preț",
    stock: "Stoc",
    quantity: "Cantitate",
    qty: "buc.",
    value: "Valoare",
    reports: "Rapoarte",
    publish_date: "Data publicării",
    no_products: "Nu există nici o carte introdusă momentan.",
    no_search_products: "Nu a fost găsită nici o carte cu termenul '{query}'.",
    no_report_data: "Nu există date pentru filtrele selectate. Încercați să ajustați filtrele",
    new_product: "Carte nouă",
    edit_product: "Modifică",
    confirm_action: "Ești sigur?",
    create_success: "Carte adăugată cu succes.",
    update_success: "Carte actualizată cu succes.",
    destroy_success: "Cartea {title} a fost ștearsă cu succes.",
    error_unexpected: "A apărut o eroare! Vă rugăm reîncercați sau contactați echipa de suport.",
    error_product_exists: "Cartea există deja în sistem. Vă rugăm folosiți un alt cod de carte sau modificați cartea existentă.",
    search: "Caută după titlu/autor",
    sort_by: "Sortează {name}",
    back_to_products: "Înapoi la lista de cărți",
    import: "Importă",
    import_successful: "Cărțile au fost importate cu succes!",
    scan: "Scanează",
    sale: "Vânzare",
    inventory: "Inventar",
    finish_inventory: "Încheie inventarul",
    finish_sale: "Încheie vânzarea",
    no_product_scanned: "Nici o carte scanată momentan.",
    loading: "Se încarcă mai multe cărți",
    client: "Editura",
    all_clients: "Toate editurile",
    select_client: "Selectează editura",
    select_file: "Selectează fișierul",
    error_client: "Trebuie să selectați o editură.",
    error_no_file: "Trebuie să selectați un fișier.",
    scan_random: "Scan random",
    error_product_not_available: "Cartea nu există în sistem. Trebuie să o introduceți mai întâi, iar pe urmă puteți relua vânzarea.",
    error_invalid_sku_length: "Identificator invalid! ISBN-ul cărții trebuie să conțină între 9 și 14 cifre. Vă rog corectați.",
    error_inventory_with_blank: "Completați toate câmpurile ce conțin stoc, titlu și preț pentru a finaliza inventarul.",
    error_order_with_blank: "Toate câmpurile trebuie completate pentru a finaliza vânzarea.",
    error_out_of_stock: "Aceasta a fost ultima carte. Nu mai aveți alte cărți pe stoc",
    stock_alert: "Rămase în stoc: {stock}"
  }
};

export default messages;
