<template>
  <nav class="navbar fixed-top navbar-expand-md navbar-light bg-light py-2">
    <a href="/" class="navbar-brand" @click="goHome">
      <i class="fas fa-book text-info"></i>
    </a>
    <button class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <slot></slot>
    </div>
  </nav>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: "AppNav",
    computed: {
      ...mapGetters(['baseUrl'])
    },
    methods: {
      goHome() {
        window.location = '/';
      }
    }
  }
</script>
