import api from '../../api'
import * as types from './types'

let timeoutId = null;
const REQUEST_TIMEOUT = 120000;
const INTERVAL_REQUEST_TIMEOUT = 500;
const SEARCH_REQUEST_TIMEOUT = 1200;

export default {
  loadInitialState({commit}, data) {
    commit(types.LOAD_STATE, data);
  },
  loadData({commit, dispatch, state}, options) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(function() {
      let page = parseInt(state.page) + 1;
      let request = api.get(state.products_url, {page: page, timeout: REQUEST_TIMEOUT, abortingXHR: true});

      request.then((response) => {
        if (response !== undefined) { response['vueMeta'] = options; } // [TODO] - temporary until https://github.com/vuejs/vuex/pull/1043
        commit(types.LOAD_STATE, response);
      }).then(() => {
        let request = api.get(`${state.base_url}/clients.json`, {timeout: REQUEST_TIMEOUT, abortingXHR: true});

        request.then((response) => {
          if (response !== undefined) { response['vueMeta'] = options; } // [TODO] - temporary until https://github.com/vuejs/vuex/pull/1043
          commit(types.LOAD_STATE, response);
        }).then(() => {
          commit(types.SET_LOADING, false);
        }).catch(() => {
          commit(types.SET_LOADING, false);
        });
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  addProduct({commit, dispatch, state}, data) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let url = `${state.base_url}/products.json`;
    timeoutId = setTimeout(function() {
      let request = api.post(url, {timeout: REQUEST_TIMEOUT, abortingXHR: true, product: data.product});

      request.then((response) => {
        if (response.success) {
          $.notify(response.message, 'success');
          commit(types.ADD_PRODUCT, response.product);
        } else {
          $.notify(response.message, 'error');
        }
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  updateProduct({commit, dispatch, state}, data) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let url = `${state.base_url}/products/${data.product.id}.json`;
    timeoutId = setTimeout(function() {
      let request = api.patch(url, {timeout: REQUEST_TIMEOUT, abortingXHR: true, product: data.product});

      request.then((response) => {
        if (response.success) {
          $.notify(response.message, 'success');
          commit(types.UPDATE_PRODUCT, response.product);
        } else {
          $.notify(response.message, 'error');
        }
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  removeProduct({commit, dispatch, state}, data) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let url = `${state.base_url}/products/${data.productId}.json`;
    timeoutId = setTimeout(function() {
      let request = api.delete(url, {timeout: REQUEST_TIMEOUT, abortingXHR: true});

      request.then((response) => {
        if (response.success) {
          $.notify(response.message, 'info');
          commit(types.REMOVE_PRODUCT, data.productId);
        } else {
          $.notify(response.message, 'error');
        }
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  setCurrentProduct({commit, dispatch, state}, data) {
    commit(types.SET_CURRENT_PRODUCT, data.product);
  },
  importFile({commit, dispatch, state}, data) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let url = `${state.base_url}/products/import.${data.format}`;
    timeoutId = setTimeout(function() {
      let request = api.post(url, data.formData, { processData: false, contentType: false });

      request.then((response) => {
        if (response.success) {
          $.notify(response.message, 'success');
          commit(types.SET_PRODUCTS, response.products);
        } else {
          $.notify(response.message, 'error');
        }
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  setBarcode({commit, dispatch, state}, data) {
    commit(types.SET_CURRENT_BARCODE, data.barcode);
  },
  setCurrentOrder({commit, dispatch,state}, data) {
    commit(types.SET_CURRENT_ORDER, data.order);
  },
  setCurrentInventory({commit, dispatch, state}, data) {
    commit(types.SET_CURRENT_INVENTORY, data.inventory);
  },
  saveInventory({commit, dispatch, state}, data) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let url = `${state.base_url}/inventory`;
    timeoutId = setTimeout(function() {
      let request = api.post(url, data);

      request.then((response) => {
        if (response.success) {
          $.notify(response.message, 'success');
          commit(types.SET_CURRENT_INVENTORY, []);
          response.products.forEach((product) => {
            let existingProduct = state.products.find((p) => {
              return p.id === parseInt(product.id);
            });
            if (existingProduct) {
              commit(types.UPDATE_PRODUCT, product);
            } else {
              commit(types.ADD_PRODUCT, product);
            }
          });
        } else {
          $.notify(response.message, 'error');
        }
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  saveOrder({commit, dispatch, state}, data) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let url = `${state.base_url}/order`;
    timeoutId = setTimeout(function() {
      let request = api.post(url, data);

      request.then((response) => {
        if (response.success) {
          $.notify(response.message, 'success');
          commit(types.SET_CURRENT_ORDER, []);
          response.products.forEach((product) => {
            let existingProduct = state.products.find((p) => {
              return p.id === parseInt(product.id);
            });
            if (existingProduct) {
              commit(types.UPDATE_PRODUCT, product);
            } else {
              commit(types.ADD_PRODUCT, product);
            }
          });
        } else {
          $.notify(response.message, 'error');
        }
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, INTERVAL_REQUEST_TIMEOUT);
  },
  addToOrder({commit, dispatch, state}, data) {
    if(data.sku) {
      let url = `${state.base_url}/products/sku.json`;
      let request = api.post(url, {sku: data.sku});

      request.then((response) => {
        if (response.product) {
          data.product = response.product;
        } else {
          data.product = { sku: data.sku, title: '', author: '', price: 0.0 };
        }
      }).then(() => {
        commit(types.ADD_PRODUCT_TO_ORDER, data);
      });
    } else {
      commit(types.ADD_PRODUCT_TO_ORDER, data);
    }
  },
  addToInventory({commit, dispatch, state}, data) {
    if(data.sku) {
      let url = `${state.base_url}/products/sku.json`;
      let request = api.post(url, {sku: data.sku});

      request.then((response) => {
        if (response.product) {
          data.product = response.product;
        } else {
          data.product = { sku: data.sku, title: '', author: '', price: 0.0 };
        }
      }).then(() => {
        commit(types.ADD_PRODUCT_TO_INVENTORY, data);
      });
    } else {
      commit(types.ADD_PRODUCT_TO_INVENTORY, data);
    }
  },
  searchProducts({commit, dispatch, state}, data) {
    commit(types.SET_LOADING, true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    let url = `${state.base_url}/products/search.json`;
    timeoutId = setTimeout(function() {
      let request = api.post(url, {query: data.query, sort_key: data.sort_key, sort_order: data.sort_order});

      request.then((response) => {
        if (response.success) {
          commit(types.SET_PRODUCTS, response.products);
        } else {
          $.notify(response.message, 'info');
        }
      }).then(() => {
        commit(types.SET_LOADING, false);
      }).catch(() => {
        commit(types.SET_LOADING, false);
      });
    }, SEARCH_REQUEST_TIMEOUT);
  }
}
