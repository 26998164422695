export const LOAD_STATE = 'LOAD_STATE';
export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
export const SET_CURRENT_BARCODE = 'SET_CURRENT_BARCODE';
export const SET_CURRENT_INVENTORY = 'SET_CURRENT_INVENTORY';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const ADD_PRODUCT_TO_INVENTORY = 'ADD_PRODUCT_TO_INVENTORY';
export const ADD_PRODUCT_TO_ORDER = 'ADD_PRODUCT_TO_ORDER';