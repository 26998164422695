<template>
  <AppDialog :show="show"
             size="md"
             :is-dismissable="true"
             class="import-dialog"
             @close="onClose">
    <div class="modal-body">
      <div class="form-group">
        <label for="client_id" class="form-label">{{$t('select_client')}}</label>
        <select ref="client" name="client_id" id="client_id" class="form-control">
          <option value="" selected></option>
          <option v-for="client in allClients" :value="client.id">{{client.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="file-upload" class="custom-file-upload btn btn-secondary">
          <i class="fas fa-upload"></i> {{$t('select_file')}}
        </label>
        <input id="file-upload"
               type="file"
               @input="onFileUpload"
        />
        <template v-if="file">
          <span>{{file.name}}</span>
        </template>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-secondary"
              @click="onClose"
      >
        {{$t('cancel')}}
      </button>
      <button type="button"
              class="btn btn-primary btn-submit"
              @click="onSave"
      >
        {{$t('save')}}
      </button>
    </div>
  </AppDialog>
</template>

<script>
  import {mapGetters} from 'vuex';

  const FILE_SIZE_LIMIT = 10485760;

  export default {
    name: "AppImportDialog",
    props: {
      show: false
    },
    data() {
      return {
        file: null
      }
    },
    computed: {
      ...mapGetters(['allClients'])
    },
    methods: {
      validateFileUpload(fileName) {
        let allowedExtensions = new Array("xlsx", "pdf", "xls", "csv");
        let fileExtension = fileName.split('.').pop().toLowerCase(); // split function will split the filename by dot(.), and pop function will pop the last element from the array which will give you the extension as well. If there will be no extension then it will return the filename.

        for (let i = 0; i <= allowedExtensions.length; i++) {
          if (allowedExtensions[i] == fileExtension) {
            return true; // valid file extension
          }
        }
        alert('Invalid file extensions! Supported extensions are: ' + allowedExtensions.join(', '))
      },
      onFileUpload() {
        let fileInput = document.getElementById('file-upload');
        this.file = fileInput.files[0];
        if (this.file.size > FILE_SIZE_LIMIT) {
          alert('max upload size is 10MB');
        } else {
          this.validateFileUpload(this.file.name);
        }
      },
      onClose() {
        this.$refs.client.value = '';
        this.file = null;
        this.$emit('close');
      },
      onSave() {
        let clientId = this.$refs.client.value;
        if(clientId) {
          if(this.file){
            let fileType = this.file.name.split('.').pop().toLowerCase();
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('client_id', clientId);
            this.$store.dispatch('importFile', {formData: formData, format: fileType});
            this.onClose();
          } else {
            alert(this.$t('error_no_file'));
          }
        } else {
          alert(this.$t('error_client'));
        }
      }
    }
  }
</script>
