// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'stock' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../locales/ro'

// Common Vue pieces
import ProductsApp from '../plugins/products_app'

// Pack-level pieces
import AppProducts from '../AppProducts.vue'
import store from '../stores/AppProductsStore'

$(() => {
  $('div[data-vue="vue-products-app"]').each(function() {
    Vue.use(VueI18n);

    const i18n = new VueI18n({
      locale: 'ro',
      messages
    });

    Vue.use(ProductsApp);

    const el = this;
    const initialState = $(el).data('initialState');
    const app = new Vue({
      el,
      i18n,
      store,
      render: h => h(AppProducts),
      created: ProductsApp.createdHook(initialState),
      mounted: ProductsApp.mountedHook()
    });
  });
});
