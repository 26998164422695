<template>
  <AppDialog :show="show"
             :is-dismissable="true"
             @close="$emit('close')">
    <div class="modal-body scan-modal-body">
      <div class="row">
        <div class="col-md">
          <div class="btn btn-lg btn-block btn-success" @click="$emit('sale')">
            {{$t('sale')}}
          </div>
        </div>
        <div class="col-md">
          <div class="btn btn-lg btn-block btn-primary" @click="$emit('inventory')">
            {{$t('inventory')}}
          </div>
        </div>
      </div>
    </div>
  </AppDialog>
</template>

<script>
  export default {
    name: "AppScanDialog",
    props: {
      show: false
    },
    methods: {
      onSave() {
        let action = this.currentProduct.id ? 'updateProduct' : 'addProduct';
        this.$store.dispatch(action, { product: this.product });
        this.$emit('close');
      }
    }
  }
</script>
