<template>
  <AppDialog :show="show"
             :title="title"
             :is-dismissable="true"
             @close="onClose">
    <div class="modal-body" v-if="product">
      <div class="row">
        <div class="col-md">
          <div class="form-group">
            <label for="client_id" class="form-label">{{$t('select_client')}}</label>
            <select v-model="product.client_id" id="client_id" class="form-control">
              <option v-for="client in allClients" :value="client.id">{{client.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="product_sku">{{$t('sku')}}</label>
            <input type="text"
                   id="product_sku"
                   class="form-control"
                   :placeholder="$t('sku')"
                   v-model="product.sku"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <div class="form-group">
            <label for="product_title">{{$t('title')}}</label>
            <input type="text"
                   id="product_title"
                   class="form-control"
                   :placeholder="$t('title')"
                   v-model="product.title"
            />
          </div>
        </div>
        <div class="col-md">
          <div class="form-group">
            <label for="product_author">{{$t('author')}}</label>
            <input type="text"
                   id="product_author"
                   class="form-control"
                   :placeholder="$t('author')"
                   v-model="product.author"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="product_price">{{$t('price')}}</label>
            <div class="input-group mb-2">
              <input type="number"
                     id="product_price"
                     class="form-control"
                     placeholder="0.0"
                     step="0.01"
                     v-model="product.price"
              />
              <div class="input-group-append">
                <div class="input-group-text">RON</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="product_publish_date">{{$t('publish_date')}}</label>
            <input id="product_publish_date"
                   type="month"
                   class="form-control"
                   :max="currentDate"
                   v-model="product.publish_date"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="product_stock">{{$t('stock')}}</label>
            <input type="number"
                   id="product_stock"
                   min="1"
                   max="10000"
                   class="form-control"
                   v-model="product.stock"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-secondary"
              @click="onClose"
      >
        {{$t('cancel')}}
      </button>
      <button type="button"
              class="btn btn-primary btn-submit"
              @click="onSave"
      >
        {{$t('save')}}
      </button>
    </div>
  </AppDialog>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {cloneDeep} from "lodash-es";
  import moment from 'moment';

  export default {
    name: "AppProductFormDialog",
    props: {
      show: Boolean
    },
    data() {
      return {
        currentDate: moment().format('YYYY-MM')
      }
    },
    computed: {
      ...mapGetters(['currentProduct', 'allClients']),
      product() {
        return cloneDeep(this.currentProduct);
      },
      title() {
        return this.currentProduct && this.currentProduct.id ? this.currentProduct.title : this.$t('new_product');
      }
    },
    methods: {
      onClose() {
        this.$emit('close');
      },
      onSave() {
        if(this.currentProduct) {
          let action = this.currentProduct.id ? 'updateProduct' : 'addProduct';
          this.$store.dispatch(action, { product: this.product });
          this.$emit('close');
        }
      }
    }
  }
</script>
