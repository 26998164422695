export default {
  baseUrl: state => state.base_url,
  showLoading: state => state.loading,
  allClients: state => state.clients,
  allProducts: state => state.products,
  currentProduct: state => state.current_product,
  previousBarcode: state => state.previous_barcode,
  currentBarcode: state => state.current_barcode,
  currentInventory: state => state.current_inventory,
  currentOrder: state => state.current_order,
  isLastPage: state => state.last_page
}