<template>
  <div>
    <div class="container-fluid">
      <div id="vue-products">
        <AppNav>
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <button class="btn btn-success"
                      @click="openModal('scan')"
              >
                <i class="fas fa-barcode"></i> {{$t('scan')}}
              </button>
            </li>
            <li class="nav-item">
              <button class="btn btn-primary"
                      @click="openModal('product')"
              >
                <i class="fas fa-plus"></i> {{$t('new_product')}}
              </button>
            </li>
            <li class="nav-item">
              <button class="btn btn-secondary" @click="openModal('import')">
                <i class="fas fa-upload"></i> {{$t('import')}}
              </button>
            </li>
          </ul>
          <template v-if="showNavbarActions">
            <button class="btn btn-default" @click="goToReports">
              <i class="fas fa-chart-line"></i> {{$t('reports')}}
            </button>
            <div class="form-inline flex-row-reverse my-2 my-lg-0">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                </div>
                <input type="text"
                       name="search"
                       class="search form-control"
                       autocomplete="false"
                       :placeholder="$t('search')"
                       :value="searchQuery"
                       @input="onInput"
                       @keydown.esc="clearSearch"
                >
                <span class="search-close"
                      v-show="searchQuery.length"
                      @click="clearSearch">
            &times;
          </span>
              </div>
            </div>
          </template>
        </AppNav>

        <AppTable :products="filteredProducts"
                  :query="searchQuery"
                  @edit-product="openModal" />
      </div>
    </div>
    <AppImportDialog :show="showModal === 'import'"
                     @close="closeModal" />
    <AppSaleDialog :show="showModal === 'sale'"
                   @close="closeModal" />
    <AppScanDialog :show="showModal === 'scan'"
                   @sale="onSale"
                   @inventory="onInventory"
                   @close="closeModal" />
    <AppInventoryDialog :show="showModal === 'inventory'"
                        @adjust-stock="adjustStockForItem"
                        @close="closeModal" />
    <AppProductFormDialog :show="showModal === 'product'"
                          :is-dismissable="true"
                          @close="closeModal" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {cloneDeep, orderBy} from 'lodash-es';

  export default {
    name: 'AppProducts',
    data() {
      return {
        productList: null,
        sortKey: 'title',
        sortOrder: 'asc',
        searchQuery: '',
        showModal: null,
        stock: 1,
        timeoutId: null
      }
    },
    beforeCreate() {
      new BarcodeScanner();
    },
    mounted() {
      $(window).on('barcode', (e, barcode) => {
        if(barcode) {
          console.log(`scanned: ${barcode}`);
          this.$store.dispatch('setBarcode', { barcode: barcode });
          if (this.showModal !== 'sale' && this.showModal !== 'inventory') {
            this.showModal = 'scan';
            this.$store.dispatch('addToInventory', { sku: barcode, stock: 1 });
            this.$store.dispatch('addToOrder', { sku: barcode, stock: 1 });
          } else if (this.showModal === 'inventory') {
            let existingItem = cloneDeep(this.currentInventory.find((i) => { return i.product.sku === barcode }));
            if (existingItem) {
              existingItem.stock += 1;
              this.$store.dispatch('addToInventory', existingItem);
            } else {
              this.$store.dispatch('addToInventory', { sku: barcode, stock: 1 });
            }
            if($('body').hasClass('modal-open')) {
              $('.modal').animate({
                scrollTop: $('.modal-open').height()
              });
            }
          } else if (this.showModal === 'sale') {
            let existingItem = cloneDeep(this.currentOrder.find((i) => { return i.product.sku === barcode }));
            if (existingItem) {
              // allow negative stocks for existing products
              existingItem.stock += 1;
              this.$store.dispatch('addToOrder', existingItem);
            } else {
              this.$store.dispatch('addToOrder', {sku: barcode, stock: 1 });
            }
            if($('body').hasClass('modal-open')) {
              $('.modal').animate({
                scrollTop: $('.modal-open').height()
              });
            }
          }
        }
      });
    },
    watch: {
      showLoading(newShowLoading) {
        $(this.$el).loadingCurtain(newShowLoading ? 'show' : 'hide');
      }
    },
    computed: {
      ...mapGetters([
        'showLoading',
        'allProducts',
        'previousBarcode',
        'currentBarcode',
        'currentInventory',
        'currentOrder'
      ]),
      filteredProducts() {
        return orderBy(this.allProducts, [this.sortKey], [this.sortOrder]);
      },
      showNavbarActions() {
        return this.filteredProducts.length > 0 || this.searchQuery.length > 0
      }
    },
    methods: {
      onSearch() {
        if(this.timeoutId) {
          clearTimeout(this.timeoutId)
        }

        this.timeoutId = setTimeout(() => {
          $("html, body").stop().animate({scrollTop:0}, 500, 'swing');
          this.$store.dispatch('searchProducts', {query: this.searchQuery})
        }, 500);
      },
      openModal(type, productId) {
        if (type === 'product') {
          let currentProduct;
          if (productId) {
            currentProduct = this.allProducts.find(p => p.id === productId);
          } else {
            currentProduct = {
              sku: this.currentBarcode,
              title: '',
              author: '',
              price: null,
              publish_date: null,
              stock: 0,
              client_id: null
            }
          }
          this.$store.dispatch('setCurrentProduct', { product: currentProduct });
        }
        this.showModal = type;
      },
      closeModal() {
        this.showModal = null;
      },
      onSale() {
        this.closeModal;
        this.$store.dispatch('setCurrentInventory', { inventory: [] });
        this.showModal = 'sale';
      },
      onInventory() {
        this.closeModal;
        this.$store.dispatch('setCurrentOrder', { order: [] });
        this.showModal = 'inventory';
      },
      adjustStockForItem(product, stock) {
        let item = this.currentInventory.find(i => i.product.sku === product.sku);
        if (item) {
          this.$store.dispatch('addToInventory', { product: product, stock: parseInt(stock) });
        }
      },
      onInput(event) {
        this.searchQuery = event.target.value;
        this.onSearch();
      },
      clearSearch() {
        $('.search').blur();
        this.searchQuery = '';
        this.onSearch();
      },
      goToReports() {
        window.location = '/reports'
      }
    }
  }
</script>
