export default {
  clients: [],
  products: [],
  loading: false,
  base_url: window.location.protocol + '//' + window.location.hostname,
  products_url: window.location.protocol + '//' + window.location.hostname + '/products.json',
  current_product: null,
  current_order: [],
  current_inventory: [],
  previous_barcode: null,
  current_barcode: null,
  page: 0,
  last_page: null,
  environment: process.env.NODE_ENV
}
