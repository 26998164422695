const reloadableErrorStatuses = [401, 403];

const reportError = (errorXHR) => {
  if (reloadableErrorStatuses.includes(errorXHR.status)) {
    window.location.reload();
  }
  ;
}

let currentRequest = null;

const request = (url, type = 'GET', data = {}, options = {}) => {
  return new Promise((resolve, reject) => {
    if (currentRequest && options.abortingXHR) {
      currentRequest.abort();
      currentRequest = null;
    }

    currentRequest = $.ajax(
      $.extend({
        url,
        type,
        data,
        dataType: 'json',
        beforeSend: (xhr) => {
          xhr.setRequestHeader(
            'X-CSRF-Token',
            $('meta[name="csrf-token"]').attr('content'))
        }
      }, options)
    );

    currentRequest
    .then(resolve)
    .fail((errorXHR, errorMessage) => {
      reportError(errorXHR, errorMessage);
      reject(errorXHR);
    })
    .done(() => {
      currentRequest = null
    })
  })
};

export default {
  request,
  get(url, params = {}, options = {}) {
    return request(url, 'GET', params, options);
  },
  post(url, params = {}, options = {}) {
    return request(url, 'POST', params, options);
  },
  patch(url, params = {}, options = {}) {
    return request(url, 'PATCH', params, options)
  },
  delete(url, params = {}, options = {}) {
    return request(url, 'DELETE', params, options)
  },
}