<template>
  <div class="row">
    <div class="col">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th scope="col">{{ $t('sku') }}</th>
          <th scope="col">{{ $t('title') }}</th>
          <th scope="col">{{ $t('author') }}</th>
          <th scope="col">{{ $t('price') }}</th>
          <th scope="col">{{ $t('stock') }}</th>
          <th scope="col">{{ $t('publish_date') }}</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <template v-if="products.length == 0">
          <tr>
            <template v-if="query.length == 0">
              <td colspan="7">{{ $t('no_products') }}</td>
            </template>
            <template v-else>
              <td colspan="7">{{ $t('no_search_products', { query: query }) }}</td>
            </template>
          </tr>
        </template>
        <template v-else>
          <tr v-for="product in products" :id="'product' + product.sku">
            <th scope="row">{{ product.sku }}</th>
            <td class="title">{{ product.title }}</td>
            <td class="author">{{ product.author }}</td>
            <td>{{ product.price }} RON</td>
            <td>{{ product.stock }}</td>
            <td>{{ publishDate(product) }}</td>
            <td>
              <div class="d-flex">
                <div class="d-flex-inline mr-4">
                  <button class="btn btn-info"
                          @click="editProduct(product.id)"
                  >
                    <i class="far fa-edit"></i>
                  </button>
                </div>
                <div class="d-flex-inline">
                  <button class="btn btn-danger btn-delete-product"
                          @click="onProductRemove(product.id)"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <tr v-show="bottom && !isLastPage && showLoading">
          <td colspan="7" class="text-center">
            <i class="fas fa-spinner fa-spin"></i> {{$t('loading')}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import moment from 'moment';

  export default {
    name: "AppTable",
    props: {
      products: Array,
      query: String
    },
    data() {
      return {
        bottom: false
      }
    },
    created() {
      window.addEventListener('scroll', () => {
        this.bottom = this.bottomVisible()
      });
      this.$store.dispatch('loadData').catch(() => {
        console.log('Error loading data');
      });
    },
    computed: {
      ...mapGetters(['isLastPage', 'showLoading']),
      publishDate() {
        return product => {
          return product.publish_date ? moment(product.publish_date).format('YYYY.MM') : 'N/A';
        }
      }
    },
    watch: {
      bottom(bottom) {
        if (bottom && !this.isLastPage && this.query.length == 0) {
          this.$store.dispatch('loadData').catch(() => {
            console.log('Error loading data');
          });
        }
      }
    },
    methods: {
      editProduct(productId) {
        this.$emit('edit-product', 'product', productId);
      },
      onProductRemove(productId) {
        if(confirm(this.$t('confirm_action'))) {
          this.$store.dispatch('removeProduct', {productId});
        }
      },
      bottomVisible() {
        const scrollY = window.scrollY;
        const visible = document.documentElement.clientHeight;
        const pageHeight = document.documentElement.scrollHeight;
        const bottomOfPage = visible + scrollY >= pageHeight;
        return bottomOfPage || pageHeight < visible
      }
    }
  }
</script>
