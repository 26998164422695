<template>
  <AppDialog :show="show"
             :title="$t('inventory')"
             :is-dismissable="false"
             size="xl"
             id="modal-inventory"
             @close="onClose">
    <div class="modal-body">
      <template v-if="environment === 'development'">
        <button type="button"
                class="btn btn-default"
                @click="onScan"
        >
          {{ $t('scan') }}
        </button>
        <button type="button"
                class="btn btn-default"
                @click="onScanRandom"
        >
          {{ $t('scan_random') }}
        </button>
      </template>
      <template v-if="inventory.length == 0 && !currentBarcode">
        {{ $t('no_product_scanned') }}
      </template>
      <template v-else>
        <div class="form-group">
          <label for="client_id" class="form-label">{{ $t('select_client') }}</label>
          <select ref="client" name="client_id" id="client_id" class="form-control">
            <option value="" selected></option>
            <option v-for="client in allClients" :value="client.id">{{ client.name }}</option>
          </select>
        </div>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('stock') }}</th>
            <th scope="col">{{ $t('sku') }}</th>
            <th scope="col">{{ $t('title') }}</th>
            <th scope="col">{{ $t('author') }}</th>
            <th scope="col">{{ $t('price') }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in inventory" :key="item.product.sku">
            <td>{{ index + 1 }}.</td>
            <td>
              <input type="number"
                     name="stock"
                     class="form-control"
                     style="width: 60px"
                     :value="item.stock"
                     @input="adjustStockForItem(item.product, ...arguments)"/>
            </td>
            <td>
              <template v-if="item.product.sku">
                {{ item.product.sku }}
              </template>
              <template v-else>
                <input type="text"
                       name="sku"
                       :value="item.product.sku"
                       @blur="updateInventoryItem(item, 'sku', $event)"
                       class="form-control"
                />
              </template>
            </td>
            <td class="title">
              <template v-if="item.product.id">
                {{ item.product.title }}
              </template>
              <template v-else>
                <input type="text"
                       name="title"
                       :value="item.product.title"
                       @input="updateInventoryItem(item, 'title', $event)"
                       class="form-control"
                />
              </template>
            </td>
            <td class="author">
              <template v-if="item.product.id">
                {{ item.product.author }}
              </template>
              <template v-else>
                <input type="text"
                       :value="item.product.author"
                       @input="updateInventoryItem(item, 'author', $event)"
                       class="form-control"/>
              </template>
            </td>
            <td>
              <template v-if="item.product.id">
                {{ item.product.price }} RON
              </template>
              <template v-else>
                <input type="number"
                       step="0.01"
                       name="price"
                       v-model="item.product.price"
                       @input="updateInventoryItem(item, 'price', $event)"
                       class="form-control"/>
              </template>
            </td>
            <td>
              <button class="btn" @click="dismissInventoryItem(item.product.sku)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-secondary"
              @click="onClose"
      >
        {{ $t('cancel') }}
      </button>
      <button type="button"
              class="btn btn-info"
              @click="addManualItem"
      >
        {{ $t('add_manual_item') }}
      </button>
      <button type="button"
              class="btn btn-primary btn-submit"
              @click="onSubmit"
      >
        {{ $t('finish_inventory') }}
      </button>
    </div>
  </AppDialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {cloneDeep, trim} from "lodash-es";

export default {
  name: "AppInventoryDialog",
  props: {show: Boolean},
  computed: {
    ...mapGetters([
      'previousBarcode',
      'currentBarcode',
      'currentInventory',
      'allClients'
    ]),
    ...mapState(['environment']),
    inventory() {
      return cloneDeep(this.currentInventory);
    }
  },
  methods: {
    setCurrentInventory() {
      this.$store.dispatch('setCurrentInventory', {inventory: this.inventory});
    },
    addManualItem() {
      let newItem = {
        stock: 1,
        product: { price: 0 }
      }
      this.inventory.push(newItem);
      this.setCurrentInventory();
      this.$store.dispatch('setBarcode', {barcode: null});
    },
    onClose() {
      this.$store.dispatch('setBarcode', {barcode: null});
      this.$store.dispatch('setCurrentInventory', {inventory: []});
      this.$emit('close');
    },
    onSubmit() {
      let valid = $('#modal-inventory').find('input[name="stock"],[name="sku"],[name="price"]').filter(function () {
        return trim($(this).val()).length === 0
      }).length === 0;
      let clientId = this.$refs.client.value;
      if (!clientId) {
        alert(this.$t('select_client'));
        return
      }
      if (valid) {
        this.$store.dispatch('setBarcode', {barcode: null});
        this.$store.dispatch('saveInventory', {inventory: this.inventory, client_id: clientId});
        this.$emit('close');
      } else {
        alert(this.$t('error_inventory_with_blank'));
      }
    },
    adjustStockForItem(item, event) {
      this.$emit('adjust-stock', item, event.target.value);
    },
    onScan() {
      this.setCurrentInventory();
      if (this.environment === 'development') {
        $(window).trigger('barcode', '9786063800696');
      }
    },
    onScanRandom() {
      this.setCurrentInventory();
      if (this.environment === 'development') {
        let barcode = Math.floor(1000000000000 + Math.random() * 9000000000000);
        $(window).trigger('barcode', barcode.toString());
      }
    },
    dismissInventoryItem(sku) {
      let index = this.inventory.findIndex((item) => {
        return item.product.sku === sku
      });
      if (index !== -1) this.inventory.splice(index, 1);
      this.setCurrentInventory();
    },
    updateInventoryItem(item, key, event) {
      let sku = event.target.value;
      if (sku.length >= 9 && sku.length <= 14) {
        item.product[key] = sku;
        setTimeout(() => {
          this.setCurrentInventory();
        }, 1000);
      } else {
        alert(this.$t('error_invalid_sku_length'));
      }
    }
  }
}
</script>
