<template>
  <AppDialog :show="show"
             :title="$t('sale')"
             :is-dismissable="false"
             size="xl"
             id="modal-sale"
             @close="onClose">
    <div class="modal-body">
      <template v-if="order.length == 0 && !currentBarcode">
        {{$t('no_product_scanned')}}
      </template>
      <template v-else>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('quantity') }}</th>
            <th scope="col">{{ $t('sku') }}</th>
            <th scope="col">{{ $t('title') }}</th>
            <th scope="col">{{ $t('author') }}</th>
            <th scope="col">{{ $t('price') }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in order">
            <td>{{index + 1}}</td>
            <td>
              <input type="number"
                     class="form-control"
                     style="width: 60px"
                     v-model="item.stock" />
              <span :class="`badge badge-pill ${stockAlertClass(item)}`"
                    v-show="item.product && stockLeft(item) < 3">
                {{$t('stock_alert', { stock: stockLeft(item) } )}}
              </span>
            </td>
            <td>{{item.product.sku}}</td>
            <td class="title">
              <template v-if="item.product.id">
                {{item.product.title}}
              </template>
              <template v-else>
                <input type="text"
                       v-model="item.product.title"
                       class="form-control"
                />
              </template>
            </td>
            <td class="author">
              <template v-if="item.product.id">
                {{item.product.author}}
              </template>
              <template v-else>
                <input type="text" v-model="item.product.author" class="form-control" />
              </template>
            </td>
            <td>
              <template v-if="item.product.id">
                {{item.product.price}} RON
              </template>
              <template v-else>
                <input type="number" v-model="item.product.price" class="form-control" />
              </template>
            </td>
            <td>
              <button class="btn" @click="dismissOrderItem(item.product.sku)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="7"><h3>Total: {{totalAmount}} RON</h3></td>
          </tr>
          </tbody>
        </table>
      </template>
    </div>
    <div class="modal-footer">
      <template v-if="environment === 'development'">
        <button type="button"
                class="btn btn-default"
                @click="onScan1"
        >
          {{$t('scan')}}
        </button>
        <button type="button"
                class="btn btn-default"
                @click="onScan2"
        >
          {{$t('scan_random')}}
        </button>
      </template>
      <button type="button"
              class="btn btn-secondary"
              @click="onClose"
      >
        {{$t('cancel')}}
      </button>
      <button type="button"
              class="btn btn-success btn-submit"
              @click="onSubmit"
      >
        {{$t('finish_sale')}}
      </button>
    </div>
  </AppDialog>
</template>

<script>
  import {mapGetters, mapState} from "vuex";
  import {cloneDeep, sumBy, trim} from "lodash-es";

  export default {
    name: "AppSaleDialog",
    props: { show: Boolean },
    computed: {
      ...mapGetters([
        'previousBarcode',
        'currentBarcode',
        'currentOrder'
      ]),
      ...mapState(['environment']),
      order() {
        return cloneDeep(this.currentOrder);
      },
      totalAmount() {
        return sumBy(this.order, function(o) { return (parseFloat(o.product.price) * parseInt(o.stock)); }).toFixed(2);
      },
      stockLeft() {
        return item => {
          if (item.product) {
            return item.product.stock - item.stock;
          }
        }
      }
    },
    methods: {
      onClose() {
        this.$store.dispatch('setBarcode', { barcode: null });
        this.$store.dispatch('setCurrentOrder', { order: [] });
        this.$emit('close');
      },
      onSubmit() {
        let valid = $('#modal-sale').find('input').filter(function () {
          return trim($(this).val()).length === 0
        }).length === 0;
        if(valid) {
          this.$store.dispatch('setBarcode', {barcode: null});
          this.$store.dispatch('saveOrder', {order: this.order});
          this.$emit('close');
        } else {
          alert(this.$t('error_order_with_blank'));
        }
      },
      onScan1() {
        if(this.environment === 'development') {
          $(window).trigger('barcode', '9786063800696');
        }
      },
      onScan2() {
        if(this.environment === 'development') {
          $(window).trigger('barcode', '9789734612307');
        }
      },
      dismissOrderItem(sku) {
        let index = this.order.findIndex((item) => {
          return item.product.sku === sku
        });
        if (index !== -1) this.order.splice(index, 1);
        this.$store.dispatch('setCurrentOrder', { order: this.order });
      },
      stockAlertClass(item){
        if(item.product.stock - item.stock <= 0) {
          return 'badge-danger'
        } else if(item.product.stock - item.stock < 3) {
          return 'badge-warning'
        }
      }
    }
  }
</script>
